.page-footer {
  background-color: $background-color-main;
}

.page-footer__wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.page-footer__contacts {
  color: #ffffff;
}
