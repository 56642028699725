.input {
  padding-top: 23px;
  padding-right: 28px;
  padding-bottom: 23px;
  padding-left: 28px;

  font-size: 24px;

  border: none;

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
