.prices-stats {
  @include clear-list();

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.prices-stats__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 250px;

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &:not(:last-child) {
    margin-right: 50px;
  }
}

.prices-stats__header {
  font-size: 32px;
  font-weight: 400;
}

.prices-stats__price {
  margin-bottom: 15px;

  font-size: 48px;
}

.prices-stats__firm-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
