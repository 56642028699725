.firms-list {
  @include clear-list();

  &--filter-company {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
  }
}

.firms-list--filter-company .firms-list__item {
  width: auto;
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 10px;


  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover {
    background-color: $background-color-main;
  }

  &--active {
    background-color: $background-color-main;
  }
}

.firms-list__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;

  color: #ffffff;

  background-color: #777777;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &--red {
    background-color: #be1e2d;
  }

  &--blue {
    background-color: #1f78B4;
  }

  &--pink {
    background-color: #e3a6a6;
  }

  &--green {
    background-color: #b2df8a;
  }

  &--yellow {
    background-color: #ffc901
  }
}
