.header {
  margin: 0;
  margin-bottom: 30px;

  font-size: 48px;
  font-weight: 400;

  &--1 {
    font-size: 48px
  }

  &--2 {
    margin-bottom: 20px;

    font-size: 36px;
  }
}
