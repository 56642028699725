.auth__wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}

.auth__logo {
  margin-bottom: 20px;
}
