.page-content__wrapper {
  padding-bottom: 50px;
  min-height: 100vh;
}

.page-content__header {
  margin-bottom: 0;
}

.page-content__table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
