.table {
  width: 100%;
}

.table__tr {
  height: 80px;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);

    transition: all 0.5s ease 0s;

    cursor: pointer;
  }
}

.table__th {
  padding-top: 25px;
  padding-right: 10px;
  padding-bottom: 25px;
  padding-left: 10px;
  width: 150px;

  text-align: left;
  color: #ffffff;

  background-color: $background-color-main;

  &:first-child {
    width: 400px;
  }

  &--main {
    color: #1d3067;
  }
}

.table__td {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 10px;

  &:not(:first-child) {
    text-align: center;
  }
}

.table__td-compare {
  display: block;

  font-size: 16px;

  &--more {
    color: #ff0000;
  }

  &--less {
    color: #019b36;
  }
}

.table__td-icon {
  margin-right: 10px;
}

.table__input-edit {
  display: flex;
  align-items: center;
}

.table__input-name {
  margin-right: 10px;
}

.table__button-wrapper {
  display: block;
  min-width: 30px;
  min-height: 30px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:hover .table__input-button {
    fill: #be1e2d;
  }
}

.table__input-button {
  transition: all 0.5s ease 0s;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
