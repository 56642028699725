/* Service styles */
@import "utils/normalize.scss";
@import "utils/variables.scss";
@import "utils/fonts.scss";
@import "utils/global-style.scss";
@import "utils/mixins.scss";

/* Blocks styles */

@import "blocks/container.scss";
@import "blocks/header.scss";
@import "blocks/text.scss";
@import "blocks/link.scss";
@import "blocks/input.scss";
@import "blocks/button.scss";
@import "blocks/icon.scss";
@import "blocks/table.scss";

@import "blocks/page-header.scss";
@import "blocks/page-content.scss";
@import "blocks/page-footer.scss";
@import "blocks/auth.scss";
@import "blocks/auth-form.scss";
@import "blocks/logo.scss";
@import "blocks/menu.scss";
@import "blocks/info-project.scss";
@import "blocks/capabilities-list.scss";
@import "blocks/table-price.scss";
@import "blocks/calendar.scss";
@import "blocks/popup-price.scss";
@import "blocks/popup-good.scss";
@import "blocks/popup-company.scss";
@import "blocks/periods-list.scss";
@import "blocks/stats-product.scss";
@import "blocks/service-buttons.scss";
@import "blocks/graph.scss";
@import "blocks/firms-list.scss";
@import "blocks/prices-stats.scss";
@import "blocks/popup-group.scss";
@import "blocks/popup-filter-firms.scss";
@import "blocks/toggle-table.scss";
@import "blocks/product-options.scss";
