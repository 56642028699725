/* Colors basic */

$basic-white: #ffffff;

/* Colors background */

$background-color-black: #000000;
$background-color-white: #fffff;
$background-color-main: #be1e2d;

$background-button: #be1e2d;

/* Colors text */

$text-white: #ffffff;
$text-color-menu: #be1e2d;
$text-color: #be1e2d;
$text-color-link-price: #00bffb;

/* Colors icon */

$icon-logo: #0000ff;

/* Colors other element */

$decorative-element: #ff0000;

/* Font-family */

$font-family-main: "Noto Sans", Arial, sans-serif;

/* Font-size */

$font-size-menu: 36px;

/* Other elements properties */

/* В данном блоке указываются свойства элементов, которые не вошли в вышеперечисленные обозначенные блоки (например, border-radius) */

/* Sizes of display */

$tablet-width: 768px;
$desktop-width: 1440px;
