/* Global style of tags */

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;

  font-family: "NotoSans", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
